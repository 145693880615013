import React, { useState } from 'react'
import { FcGoogle } from 'react-icons/fc';
import Link from 'next/link';
import swal from 'sweetalert'
import Head from 'next/head';
import * as API from "../../utils/api";
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';


function PageApp() {

  const onForgotPassword = async () => {
    const email = await swal({
      title: 'Forgot Password?',
      text: 'Enter your email to reset your password',
      icon: 'info',
      content: "input",
      buttons: ['Cancel', 'Reset Password'],
    })

    if (!email) return;
    try {
      const res = await API.PostAPI('/api/email/password/forgot', { email });
      swal('Reset Password', res.message, res.result ? 'success' : 'warning');
    } catch (e) {
      console.log(e.message);
      swal('Forgot Password', 'Something went wrong', 'error')
    }
  }

  return (
    <div>
      <Head>
        <title>Lindle</title>
<meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="All your links, All at once, All in one place!" />
        <link rel="icon" href="/favicon.ico" />

        {/* Facebook */}
        <meta property="og:title" content="Lindle" />
        <meta property="og:description" content="All your links, All at once, All in one place!" />
        <meta property="og:image" content="/logoText.png" />
        <meta property="og:url" content="https://lindle.me" />
        <meta property="og:type" content="website" />

        <meta name="twitter:title" content="Lindle" />
        <meta name="twitter:description" content="All your links, All at once, All in one place!" />
        <meta name="twitter:image" content="/logoText.png" />
        <meta name="twitter:card" content="/bannerText.png" />

        {/* pinterest */}
        <meta name="pinterest-rich-pin" content="true" />
      </Head>
 
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-10 rounded-lg shadow-md w-1/2">
          <h2 className="text-2xl font-semibold mb-6">Login to Lindle</h2>
          <form method='POST' action='/api/email/oauth?web=true'>
            <div className="mb-4">
              <label htmlFor="username" className="block text-sm font-medium text-gray-600">
                Email
              </label>
              <input
                type="text"
                id="email"
                name="email"
                className="mt-1 p-2 w-full rounded-md bg-gray-200 focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="mt-1 p-2 w-full rounded-md bg-gray-200 focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-500 text-white p-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring focus:border-green-300"
            >
              Login
            </button>

            <Link
              href="/api/google/oauth?web=true"
              className="my-7 w-full flex justify-center text-green-800 items-center bg-slate-100 p-2 rounded-md hover:bg-green-900 hover:text-white focus:outline-none focus:ring focus:border-slate-300"
            >
              <FcGoogle size={30} className='mx-2' /> Continue with Google
            </Link>
            <div className='flex justify-center items-center gap-10 text-green-700'>
              <Link href="#" onClick={onForgotPassword}>Forgot Password?</Link>
              <Link href="/app/signup">I Do not Have An Account</Link>
            </div>
          </form>
        </div>
      </div>
 
    </div>
  )
}

export default PageApp